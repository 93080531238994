import React from "react"
import Button from "../../components/button"
import SubHeading from "../../components/copy/subHeading"
import Gallery from "../../components/gallery"
import "./index.scss"

const ProjectDetailScreen = ({ project }) => {
  const {
    address,
    flyers,
    projectImages,
    sitePlans,
    unit,
    pks,
    banner
  } = project

  const data = [
    {
      label: "Alamat",
      text: address ? address : null
    },
    {
      label: "Unit",
      text: unit ? `+- ${unit} Unit` : null
    }
  ]

  const renderBannerCarousel = () => (
    <div
      className="projectDetail-bannerCarousel"
    >
      <img
        className="projectDetail-bannerCarouselImage"
        src={banner.url}
        alt=""
      />
    </div>
  )

  const renderProjectInfo = () => (
    <div className="projectDetail-info">
      <h2 className="projectDetail-name u-margin0 u-heading1">{project.name}</h2>
      {
        data.map(item => {
          if (item.text) {
            return (
              <div
                className="projectDetail-infoWrapper"
                key={item.text}
              >
                <p className="projectDetail-infoLabel u-margin0 u-body1">{item.label}</p>
                <p className="projectDetail-infoText u-margin0 u-body1">{item.text}</p>
              </div>
            )
          }

          return null
        })
      }
    </div>
  )

  const renderDownloadPK = () => {
    if (!pks || !pks.length) {
      return null
    }

    return (
      <div className="projectDetail-downloadPK">
        {
          pks.map((pk, index) => (
            <a
              href={pk.url}
              target="__blank"
              key={index}
            >
              <Button className="projectDetail-downloadPKButton">
                <p className="u-margin0">Download {pk.label}</p>
              </Button>
            </a>
          ))
        }
      </div>
    )
  }

  const renderProjectSections = (title, galleries) => (
    <div className="projectDetail-section">
      <SubHeading
        text={title}
      />
      <div className="projectDetail-sectionFeed">
        <Gallery
          galleries={galleries}
        />
      </div>
    </div>
  )

  return (
    <div className="projectDetail-screen">
        {renderBannerCarousel()}
      <div className="projectDetail-body">
        {renderProjectInfo()}
        {renderDownloadPK()}
        {flyers && flyers.length > 0 && renderProjectSections("Flyers", flyers)}
        {projectImages && projectImages.length > 0 && renderProjectSections("Foto Proyek", projectImages)}
        {sitePlans && sitePlans.length > 0 && renderProjectSections("Siteplan", sitePlans)}
      </div>
    </div>
  )
}

export default ProjectDetailScreen
