import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectDetailScreen from "../screens/projectDetail"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title={data.project.name} />
      <ProjectDetailScreen
        project={data.project}
      />
    </Layout>
  )
}

export const query = graphql`
  query ProductDetail($id: String!) {
    project(id: {eq: $id}) {
      id
      name
      address
      unit
      banner {
        url
      }
      flyers {
        url
      }
      projectImages {
        url
      }
      sitePlans {
        url
      }
      pks {
        url
        label
      }
    }
  }
`
